<template>
  <!--  THIS DIV WILL GET THE "m-container" class from the Dashboard component-->
  <div class="m-wrapper">
    <div class="notifications-section">
      <p class="section-title">الإشعارات الحالية:</p>
      <div class="controle-container">
        <button
          class="button is-danger"
          @click="archiveAllnotifications"
          :disabled="!showNotifications"
        >
          مسح كل الإشعارات
        </button>
      </div>
      <div v-if="showNotifications" class="m-notifications-container">
        <div
          v-for="notification in $store.getters['notifications/notifications']"
          :key="notification.id"
          class="m-notifications-container__notification"
          :class="[notification.type]"
        >
          <div class="notification__title">
            <font-awesome-icon icon="exclamation-triangle" />
            {{ notification.title }}
            <p class="title__date">{{ parseDate(notification.date) }}</p>
          </div>
          <div class="notification__details" v-html="notification.details"></div>
        </div>
      </div>
      <p v-else class="empty-state-text">لايوجد لديك إشعارات حاليا.</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    parseDate(d) {
      return moment(d)
        .utc()
        .format("D/M/YYYY");
    },
    archiveAllnotifications() {
      if (this.$store.getters["notifications/notifications"].length !== 0) {
        this.$store
          .dispatch("notifications/archiveAllNotifications")
          .then(success => {
            if (success) {
              this.$buefy.toast.open({
                duration: 3000,
                message: "تم حذف الإشعارات",
                position: "is-top",
                type: "is-success"
              });
            }
          })
          .catch(error => { // eslint-disable-line
            console.log(error);
            this.$buefy.toast.open({
              duration: 3000,
              message: "حدث خطأ ما أثناء حذف التنبيهات",
              position: "is-top",
              type: "is-danger"
            });
          });
      }
    }
  },
  computed: {
    showNotifications() {
      return (
        this.$store.getters["notifications/notifications"] &&
        this.$store.getters["notifications/notifications"].length > 0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/_variables";

.section-title *,
.controle-container,
.m-notifications-container {
  direction: rtl;
}

.notifications-section {
  .empty-state-text {
    direction: rtl;
  }
}

.m-notifications-container {
  font-size: 16px;
  font-family: "Dubai-Bold";

  .m-notifications-container__notification {
    position: relative;
    padding: 15px;
    border-radius: $border-radius-xs;
    margin-bottom: 15px;
    color: #212529;
    $p: &;

    .closing-x {
      position: absolute;
      top: 10px;
      left: 10px;
      // background: black;
      // width: 10px;
      // height: 10px;
    }

    .notification__title {
      font-size: 19px;
      line-height: 19px;
      font-weight: bold;
      margin-bottom: 15px;
      position: relative;
      // display: flex;
      // flex-wrap: wrap;
      // align-content: center;
      // align-items: center;

      .circle {
        width: 17px;
        height: 17px;
        border-radius: 50px;
        background: red;
        margin-left: 15px;
      }

      .title__date {
        font-size: 15px;
        font-weight: 100;
        color: #212529;
        width: 100%;
        line-height: 15px;
        margin-top: 8px;
      }

      i {
        margin-left: 10px;
      }
    }

    .notification__details {
      font-size: 17px;
      color: #212529;
    }

    &.success {
      // box-shadow: $success-shadow-box;
      color: $success;
      border: 3px solid $success;
    }

    &.warn {
      // box-shadow: $warn-shadow-box;
      color: $warn;
      border: 3px solid $warn;
      .notification__title {
        &::after {
          background: $warn;
        }
      }
    }

    &.danger {
      // box-shadow: $danger-shadow-box;
      color: $danger;
      border: 3px solid $danger;
      .notification__title {
        &::after {
          background: $danger;
        }
      }
    }

    &.dark {
      // box-shadow: $danger-shadow-box;
      color: $dark;
      border: 3px solid $dark;
      .notification__title {
        &::after {
          background: $danger;
        }
      }
    }
  }
}
</style>
